body {
    background-color: var(--bg-color);
    font-family: var(--font-family);
}

.app__main {
    width: 100%;
}

.portfolio__content-divs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 80%;
}

.app__hero-animation {
     display: grid;
     position: relative;
     width: 100%;
     margin-bottom: 2rem;
}

h1, h2 {
    background-image: linear-gradient(90deg, rgb(239, 238, 238) 0%, rgb(96, 95, 96) 100%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 42px;
}

.short__h1 {
    margin: 0 auto;
    width: 85%;
    background-image: linear-gradient(90deg, rgb(239, 238, 238) 0%, rgb(96, 95, 96) 15%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;

    position: relative;
}

p {
    color: #d0cfd0; 
}


span {
    color: #F2F2F2;
}

a {
    color: white;
    text-decoration: none;
    font-size: 16px;
}

.flex {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.portfolio__content {
    flex-direction: column;
    width: 85%;
    margin: 2rem auto;
}

.decoration__span-above {
    position: absolute;
    width: 50px;
    height: 2px;
    background-color: rgb(248, 147, 111);

    
}

.decoration__span {
    display: inline-block;
    width: 50px;
    height: 2px;
    background-color: #ffc20a;
}

@media screen and (max-width: 1024px) {
    .projects__grid-container {
        width: 70%;
    }

    h1 {
        font-size: 32px;
    }

    .portfolio__content-divs {
        width: 90%;
        margin: 0 auto;
    }

    .hero__left {
        width: 85%;
        height: 25vh;
        margin: 0 auto;
    }

    .app__hero-animation {
        width: 100%;
        height: 30vh;
        margin-bottom: 4rem;
        margin-top: 2rem;
    }

    #svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

@media screen and (max-width: 450px) {

    .app__hero-animation{
        margin-top: 0;
        margin-bottom: 7rem;
    }
    .short__h1 {
        width: 100%;
    }
    body {
        margin: 0;
    }
    .app__main {
        width: 100vw;
        margin: 0;
        padding: 0;
    }

    h1 {
        font-size: 26px;
    }

    .portfolio__content {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .projects__grid-container {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    #svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

@media screen and (max-width: 380px) {
    .app__hero-animation{
        margin-bottom: 11rem;
    }
}