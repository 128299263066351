.footer__main-container {
    width: 85%;
    margin: 0 auto;
}

.footer__break-line {
    width: 100%;
    height: 1px;
    margin: 3rem 0 3rem 0;;
    background-color: rgba(115, 115, 115, 0.493);
}

.footer__info {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 4rem;
    
}

.footer__info div {
    width: 30%;
    
}

.footer__p-and-socials {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2rem 0;
}

.footer__p {
    font-size: 14px;
}

.footer__socials {
    display: flex;
    align-items: center;
    
    
}

.footer__socials div {
    margin: 0 10px;
}

@media screen and (max-width: 450px) {
    .footer__info, .footer__p-and-socials {
        flex-direction: column;
    }

    .footer__socials div {
        width: 100%;
        display: flex;
    } 

}
